import axios from '../api/axios';
import useAuth from './useAuthHook';
import useCookiesHook from './useCookiesHook';

const useRefreshTknHook = () => {
    const { setAuth } = useAuth();
    const { setCookieName, setCookie } = useCookiesHook();

    return async () => {
        const response = await axios.get('/webUser/refreshToken', {
            withCredentials: true
        });
        setAuth(prev => {
            return {...prev,
                accessToken: response.data[0],
                id: response.data[1],
                username: response.data[2]}
        });
        setCookieName('login');
        setCookie('login', true, {maxAge: 24*60*60*1000, sameSite:true})
        return response.data;
    };
};

export default useRefreshTknHook;
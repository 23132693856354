import AOS from "aos";
import React from "react";
import "aos/dist/aos.css";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import App from "./App";
import "./index.css";

import {UseAuthContext} from './context/useAuthContext';
import {UseCartContext} from "./context/useCartContext";
import {CookiesProvider} from 'react-cookie';

AOS.init();

const root = document.getElementById("root");
ReactDOM.createRoot(root).render(
    <React.StrictMode>
        <CookiesProvider defaultSetOptions={{path: '/'}}>
            <BrowserRouter>
                <UseAuthContext>
                    <UseCartContext>
                        <Routes>
                            <Route path="/*" element={<App/>}/>
                        </Routes>
                    </UseCartContext>
                </UseAuthContext>
            </BrowserRouter>
        </CookiesProvider>
    </React.StrictMode>
);

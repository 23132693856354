import InputCom from "../../../Helpers/InputCom";
import useUserHook from "../../../../hooks/useUserHook";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import axios, {axiosPrivate} from "../../../../api/axios";

export default function ProfileTab() {
    // const [profileImg, setprofileImg] = useState(null);
    // const profileImgInput = useRef(null);
    // const browseprofileImg = () => {
    //     profileImgInput.current.click();
    // };
    // const profileImgChangHandler = (e) => {
    //     if (e.target.value !== "") {
    //         const imgReader = new FileReader();
    //         imgReader.onload = (event) => {
    //             setprofileImg(event.target.result);
    //         };
    //         imgReader.readAsDataURL(e.target.files[0]);
    //     }
    // };

    const editUser = {
        userFName: '',
        userLName: '',
        userContact: '',
        userAddress: '',
        userCity: '',
        userZip: '',
    }
    const {userDetails} = useUserHook()
    const [userData, setUserData] = useState(editUser)
    const [formErrors, setFormErrors] = useState({});
    const [formSubmit, setFormSubmit] = useState(false);
    const setEditUser = () => {
        setUserData({
                ...editUser, userFName: userDetails.webUserFName,
                userLName: userDetails.webUserLName,
                userContact: userDetails.webUserContact,
                userAddress: userDetails.webUserAddress,
                userCity: userDetails.webUserCity,
                userZip: userDetails.webUserZip,
            }
        )
    }

    useEffect(() => {
        setEditUser()
    }, [userDetails]);

    const handelInputs = (e) => {
        const {id, value} = e.target;
        setUserData({...userData, [id]: value})
        setFormErrors({...formErrors, [id]: ''})
        // setFormSubmit(false)
    }

    const validation = (v) => {
        const phoneNumberRegex = /^(?:\+?1[-.\s]?)?(?:\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;

        const errors = {};
        if (v.userFName === "") {
            errors.userFName = "required";
        }
        if (v.userLName === "") {
            errors.userLName = "required";
        }
        if (v.userContact === "") {
            errors.userContact = "required";
        }
        if (v.userAddress === "") {
            errors.userAddress = "required";
        }
        if (v.userCity === "") {
            errors.userCity = "required";
        }
        if (v.userZip === "") {
            errors.userZip = "required";
        }
        setFormSubmit(false)
        return errors;
    }

    const handleSubmit = () => {
        setFormErrors(validation(userData))
        setFormSubmit(true)
    }

    useEffect(() => {
        const controller = new AbortController()
        const updateUser = async () => {
            return await axiosPrivate.post(
                'http://localhost:3500/webUser',
                userData,
                {
                    signal: controller.signal
                }
            )
        }
        if (formSubmit){
            updateUser().then(response => {
                toast.success("Update Successfully Complied")
                setFormSubmit(false)
                setEditUser()
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warning("Username and Password are required")
                    }
                    if (error.response.status === 409) {
                        toast.warning("Your Email or Phone Number Already using")
                    }
                } else {
                    toast.error("Registration Failed Please Try again later")
                }
            });
        }

    }, [])

    return (
        <>
            <div className="flex space-x-8">
                <div className="w-full">
                    <div className="input-item flex space-x-2.5 mb-8">
                        <div className="w-1/2 h-full">
                            <InputCom
                                label="First Name*"
                                placeholder="Demo Name"
                                type="text"
                                inputClasses="h-[50px]"
                                name='userFName'
                                value={userData.userFName}
                                inputHandler={handelInputs}
                            />
                            <small className="text-red-700">{formErrors.userFName}</small>
                        </div>

                        <div className="w-1/2 h-full">
                            <InputCom
                                label="Last Name*"
                                placeholder="Demo Name"
                                type="text"
                                inputClasses="h-[50px]"
                                name='userLName'
                                value={userData.userLName}
                                inputHandler={handelInputs}
                            />
                            <small className="text-red-700">{formErrors.userLName}</small>
                        </div>
                    </div>
                    <div className="input-item flex space-x-2.5 mb-8">
                        <div className="w-1/2 h-full">
                            <InputCom
                                label="Phone Number*"
                                placeholder="012 3  *******"
                                type="text"
                                inputClasses="h-[50px]"
                                name='userContact'
                                value={userData.userContact}
                                inputHandler={handelInputs}
                            />
                            <small className="text-red-700">{formErrors.userContact}</small>
                        </div>
                    </div>
                    <div className="input-item mb-8">
                        <div className="w-full">
                            <InputCom
                                label="Address*"
                                placeholder="your address here"
                                type="text"
                                inputClasses="h-[50px]"
                                name='userAddress'
                                value={userData.userAddress}
                                inputHandler={handelInputs}
                            />
                            <small className="text-red-700">{formErrors.userAddress}</small>
                        </div>
                    </div>
                    <div className="input-item flex space-x-2.5 mb-8">
                        <div className="w-1/2 h-full">
                            <InputCom
                                label="Town / City*"
                                placeholder=""
                                type="text"
                                inputClasses="h-[50px]"
                                name='userCity'
                                value={userData.userCity}
                                inputHandler={handelInputs}
                            />
                            <small className="text-red-700">{formErrors.userCity}</small>
                        </div>
                        <div className="w-1/2 h-full">
                            <InputCom
                                label="Postcode / ZIP*"
                                placeholder=""
                                type="text"
                                inputClasses="h-[50px]"
                                name='userZip'
                                value={userData.userZip}
                                inputHandler={handelInputs}
                            />
                            <small className="text-red-700">{formErrors.userZip}</small>
                        </div>
                    </div>
                </div>
            </div>
            <div className="action-area flex space-x-4 items-center">
                <button
                    onClick={handleSubmit}
                    type="button"
                    className="w-[164px] h-[50px] bg-qblack text-white text-sm"
                >
                    Update Profile
                </button>
            </div>
        </>
    );
}

import { Link } from "react-router-dom";
import Facebook from "../../../Helpers/icons/Facebook";
import Instagram from "../../../Helpers/icons/Instagram";
import Twitter from "../../../Helpers/icons/Twitter";

export default function TopBar({ className }) {
  return (
    <>
      <div
        className={`w-full bg-white h-10 border-b border-qgray-border ${
          className || ""
        }`}
      >
        <div className="container-x mx-auto h-full">
          <div className="flex justify-between items-center h-full">
            <div className="topbar-nav">
              <ul className="flex space-x-6">
                {/* <li>
                  <Link to="/">
                    <span className="text-xs leading-6 text-qblack font-500">
                      Account
                    </span>
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="/tracking-order">
                    <span className="text-xs leading-6 text-qblack font-500">
                      Track Order
                    </span>
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="/faq">
                    <span className="text-xs leading-6 text-qblack font-500">
                      Support
                    </span>
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className="topbar-dropdowns sm:block hidden">
              <div className="flex space-x-6">
                <div className="country-select flex space-x-1 items-center">
                  <Link to="https://web.facebook.com/items.lk7">
                    <Facebook className="fill-current text-qgray hover:text-qblack" />
                  </Link>       
                </div>
                <div className="currency-select flex space-x-1 items-center">
                  <Link to="https://www.instagram.com/items.lk/" target="_blank">
                    <Instagram className="fill-current text-qgray hover:text-qblack" />
                  </Link>
                </div>
                {/* <div className="language-select flex space-x-1 items-center">
                  <Link to="https://twitter.com/?lang=en">
                    <Twitter className="fill-current text-qgray hover:text-qblack" />
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

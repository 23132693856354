import {Outlet} from "react-router-dom";
import {useEffect, useState} from "react";
import useRefreshTknHook from "../../hooks/useRefreshTknHook";
import useAuthHook from "../../hooks/useAuthHook";

const persistLogin = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isLoading, setIsLoading] = useState(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const refreshTkn = useRefreshTknHook();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {auth} = useAuthHook();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        let isMounted = true;
        const verifyRefreshToken = async () => {
            try {
                await refreshTkn();
            } catch (e) {
                console.error(e)
            } finally {
                isMounted && setIsLoading(false);
            }
        }
        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
    }, [auth?.accessToken, refreshTkn]);

    return (
        <>
            {isLoading ? <p> Loading </p> : <Outlet/>}
        </>
    )
}
export default persistLogin
import {Dialog, Transition} from '@headlessui/react'
import React, {useState, Fragment, useEffect} from "react";

export default function OrderTab() {

    let [isOpen, setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    useEffect(() => {
    }, [])

    return (
        <>
            <div className="relative w-full overflow-x-auto sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <tbody>
                    {/* table heading */}
                    <tr className="text-base text-qgray whitespace-nowrap px-2 border-b default-border-bottom ">
                        <td className="py-4 block whitespace-nowrap text-center">
                            Order
                        </td>
                        <td className="py-4 whitespace-nowrap text-center">Date</td>
                        <td className="py-4 whitespace-nowrap text-center">Status</td>
                        <td className="py-4 whitespace-nowrap text-center">Amount</td>
                        <td className="py-4 whitespace-nowrap  text-center">Action</td>
                    </tr>
                    {/* table heading end */}
                    <tr className="bg-white border-b hover:bg-gray-50">
                        <td className="text-center py-4">
                            <span className="text-lg text-qgray font-medium">#354</span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-base text-qgray  whitespace-nowrap">
                  Fub 05,2021
                </span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-sm rounded text-green-500 bg-green-100 p-2">
                  Completed
                </span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-base text-qblack whitespace-nowrap px-2 ">
                  $757
                </span>
                        </td>
                        <td className="text-center py-4">
                            <button
                                type="button"
                                onClick={openModal}
                                className="w-[116px] h-[46px] bg-qyellow text-qblack font-bold"
                            >
                                View Details
                            </button>
                        </td>
                    </tr>
                    <tr className="bg-white border-b hover:bg-gray-50">
                        <td className="text-center py-4">
                            <span className="text-lg text-qgray font-medium">#354</span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-base text-qgray  whitespace-nowrap">
                  Fub 05,2021
                </span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-sm rounded text-green-500 bg-green-100 p-2">
                  Completed
                </span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-base text-qblack whitespace-nowrap px-2 ">
                  $757
                </span>
                        </td>
                        <td className="text-center py-4">
                            <button
                                type="button"
                                className="w-[116px] h-[46px] bg-qyellow text-qblack font-bold"
                            >
                                View Details
                            </button>
                        </td>
                    </tr>
                    <tr className="bg-white border-b hover:bg-gray-50">
                        <td className="text-center py-4">
                            <span className="text-lg text-qgray font-medium">#354</span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-base text-qgray  whitespace-nowrap">
                  Fub 05,2021
                </span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-sm rounded text-green-500 bg-green-100 p-2">
                  Completed
                </span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-base text-qblack whitespace-nowrap px-2 ">
                  $757
                </span>
                        </td>
                        <td className="text-center py-4">
                            <button
                                type="button"
                                className="w-[116px] h-[46px] bg-qyellow text-qblack font-bold"
                            >
                                View Details
                            </button>
                        </td>
                    </tr>
                    <tr className="bg-white border-b hover:bg-gray-50">
                        <td className="text-center py-4">
                            <span className="text-lg text-qgray font-medium">#354</span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-base text-qgray  whitespace-nowrap">
                  Fub 05,2021
                </span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-sm rounded text-green-500 bg-green-100 p-2">
                  Completed
                </span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-base text-qblack whitespace-nowrap px-2 ">
                  $757
                </span>
                        </td>
                        <td className="text-center py-4">
                            <button
                                type="button"
                                className="w-[116px] h-[46px] bg-qyellow text-qblack font-bold"
                            >
                                View Details
                            </button>
                        </td>
                    </tr>
                    <tr className="bg-white border-b hover:bg-gray-50">
                        <td className="text-center py-4">
                            <span className="text-lg text-qgray font-medium">#354</span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-base text-qgray  whitespace-nowrap">
                  Fub 05,2021
                </span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-sm rounded text-green-500 bg-green-100 p-2">
                  Completed
                </span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-base text-qblack whitespace-nowrap px-2 ">
                  $757
                </span>
                        </td>
                        <td className="text-center py-4">
                            <button
                                type="button"
                                className="w-[116px] h-[46px] bg-qyellow text-qblack font-bold"
                            >
                                View Details
                            </button>
                        </td>
                    </tr>
                    <tr className="bg-white border-b hover:bg-gray-50">
                        <td className="text-center py-4">
                            <span className="text-lg text-qgray font-medium">#354</span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-base text-qgray  whitespace-nowrap">
                  Fub 05,2021
                </span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-sm rounded text-green-500 bg-green-100 p-2">
                  Completed
                </span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-base text-qblack whitespace-nowrap px-2 ">
                  $757
                </span>
                        </td>
                        <td className="text-center py-4">
                            <button
                                type="button"
                                className="w-[116px] h-[46px] bg-qyellow text-qblack font-bold"
                            >
                                View Details
                            </button>
                        </td>
                    </tr>
                    <tr className="bg-white border-b hover:bg-gray-50">
                        <td className="text-center py-4">
                            <span className="text-lg text-qgray font-medium">#354</span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-base text-qgray  whitespace-nowrap">
                  Fub 05,2021
                </span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-sm rounded text-green-500 bg-green-100 p-2">
                  Completed
                </span>
                        </td>
                        <td className="text-center py-4 px-2">
                <span className="text-base text-qblack whitespace-nowrap px-2 ">
                  $757
                </span>
                        </td>
                        <td className="text-center py-4">
                            <button
                                type="button"
                                className="w-[116px] h-[46px] bg-qyellow text-qblack font-bold"
                            >
                                View Details
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            {/* Modal for view order data */}
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25"/>
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto z-50">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all z-50">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Order Info
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <div className="flex w-full">
                                            <div className="product-details w-full mt-10 lg:mt-0 mr-[15px]">
                        <span
                            data-aos="fade-up"
                            className="text-qgray text-xs font-normal uppercase tracking-wider mb-2 inline-block"
                        >
                          Mobile Phones
                        </span>
                            <p
                                data-aos="fade-up"
                                className="text-xl font-medium text-qblack mb-4"
                            >
                                Samsung Galaxy Z Fold3 5G 512GB
                            </p>

                                                <div data-aos="fade-up" className="flex space-x-2 items-center mb-7">
                          <span className="text-sm font-500 text-qgray line-through mt-2">
                            $1299.00
                          </span>
                                                    <span className="text-2xl font-500 text-qred">$1119.00</span>
                                                </div>

                                                <div data-aos="fade-up" className="colors mb-[30px]">
                          <span className="text-sm font-normal uppercase text-qgray mb-[14px] inline-block">
                            COLOR
                          </span>

                                                    <div className="flex space-x-4 items-center">
                                                        <button
                                                            type="button"
                                                            className="w-[20px] h-[20px]  rounded-full focus:ring-2  ring-offset-2 flex justify-center items-center"
                                                        >
                              <span
                                  className="w-[20px] h-[20px] block rounded-full border"
                              ></span>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div data-aos="fade-up" className="mb-[20px]">
                                                    <p className="text-[13px] text-qgray leading-7">
                                                        <span className="text-qblack">Category :</span> Mobile Phones
                                                    </p>
                                                    <p className="text-[13px] text-qgray leading-7">
                                                        <span className="text-qblack">Tags :</span> Mobile Phones, Smart
                                                        Phones, Samsung
                                                    </p>
                                                    <p className="text-[13px] text-qgray leading-7">
                                                        <span className="text-qblack">SKU:</span> SM-101037
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="w-[116px] h-[46px] bg-qyellow text-qblack rounded-xl"
                                            onClick={closeModal}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}

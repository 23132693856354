import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCiLo6k-TEstNEyt9GmDjkpuPQv3HVN3cM",
    authDomain: "itemslk-407307.firebaseapp.com",
    projectId: "itemslk-407307",
    storageBucket: "itemslk-407307.appspot.com",
    messagingSenderId: "110128443764",
    appId: "1:110128443764:web:6107244c6f8fe63996bc21",
    measurementId: "G-ZN47MC0TRP"
};
export default initializeApp(firebaseConfig);
import {useEffect, useState} from "react";
import useAxiosHook from "./useAxiosHook";
import useAuthHook from "./useAuthHook";
import {useLocation, useNavigate} from "react-router-dom";

const useUserHook = () => {
    const user = {
        webUserFName: "",
        webUserLName: "",
        webUserUserName: "",
        webUserContact: "",
        webUserAddress: "",
        webUserCity: "",
        webUserZip: "",
    }

    const [userDetails, setUserDetails] = useState(user);
    const UseAxiosHook = useAxiosHook()
    const {auth} = useAuthHook()
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getUsers = async () => {
            return await UseAxiosHook.get('/webUser/getDetails/' + auth.id, {
                signal: controller.signal
            });
        }

        getUsers().then((response)=>{
            isMounted && setUserDetails(response.data[0]);
        }).catch((error) => {
            console.error(error);
            navigate('/login', { state: { from: location }, replace: true });
        }).finally(()=>{
            isMounted = false;
            controller.abort()
        });

    }, [])

    return {userDetails}
}
export default useUserHook;
import React, { useEffect, useMemo, useState } from "react";
import InputCom from "../../Helpers/InputCom";
import Layout from "../../Partials/Layout";
import Thumbnail from "./Thumbnail";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import {Link, useNavigate} from 'react-router-dom';

export default function Signup() {
    // eslint-disable-next-line no-restricted-globals,react-hooks/exhaustive-deps
    const initialVale = {
        f_name: '',
        l_name: '',
        contact: '',
        address: '',
        city: '',
        zip: '',
        email: '',
        password: '',
        rePassword: '',
    };
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState(initialVale);
    const [formErrors, setFormErrors] = useState({});
    const [formSubmit, setFormSubmit] = useState(false);
    const [checked, setValue] = useState(false);

    const rememberMe = () => {
        setValue(!checked);
    };

    const handelInputs = (e) => {
        const { id, value } = e.target;
        setFormValues({ ...formValues, [id]: value })
        setFormErrors({ ...formErrors, [id]: '' })
        setFormSubmit(false)
    }

    const validation = (v) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,}$/;
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const phoneNumberRegex = /^(?:\+?1[-.\s]?)?(?:\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;

        const errors = {};
        if (v.f_name === "") {
            errors.f_name = "required";
        }
        if (v.l_name === "") {
            errors.l_name = "required";
        }
        if (v.contact === "") {
            errors.contact = "required";
        }
        if (v.address === "") {
            errors.address = "required";
        }
        if (v.city === "") {
            errors.city = "required";
        }
        if (v.zip === "") {
            errors.zip = "required";
        }
        if (v.email === "") {
            errors.email = "required";
        }
        if (v.password === "") {
            errors.password = "required";
        }
        if (v.rePassword === "") {
            errors.rePassword = "required";
        }
        if ((v.password !== "" && v.rePassword !== "") && v.password !== v.rePassword) {
            errors.rePassword = "Password missed matched";
        }
        setFormSubmit(false)
        return errors;
    }

    const handleSubmit = () => {
        setFormErrors(validation(formValues))
        if (!checked) {
            toast.info("places read and click agree to all terms and conditions")
            setFormSubmit(false)
        } else {
            setFormSubmit(true)
        }
    }
    
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && formSubmit && checked) {
            const controller = new AbortController()
            const saveUser = async () => {
                return await axios.post(
                    'http://localhost:3500/webUser',
                    formValues,
                    {
                        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                        withCredentials: true,
                        signal: controller.signal
                    }
                )
            }
            
            saveUser().then(response => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                toast.success("Registration Successfully Complied Please Login to Continue")
                setFormSubmit(false)
                setFormValues(initialVale)
                setTimeout(() => {
                    navigate('/login')
                }, 1500)

            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warning("Username and Password are required")
                    }
                    if (error.response.status === 409) {
                        toast.warning("Your Email or Phone Number Already using")
                    }
                } else {
                    toast.error("Registration Failed Please Try again later")
                }
            });
        }
    }, [checked, formErrors, formSubmit, formValues, initialVale, navigate])

    return (
        <Layout childrenClasses="pt-0 pb-0">
            <div className="login-page-wrapper w-full py-10">
                <div className="container-x mx-auto">
                    <div className="lg:flex items-center relative">
                        <div
                            className="lg:w-[572px] w-full lg:h-[900px] bg-white flex flex-col justify-center sm:p-10 p-5 border border-[#E0E0E0]">
                            <div className="w-full">
                                <div
                                    className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                                    <h1 className="text-[34px] font-bold leading-[74px] text-qblack">
                                        Create Account
                                    </h1>
                                    {/* <div className="shape -mt-6">
                                        <svg
                                            width="354"
                                            height="30"
                                            viewBox="0 0 354 30"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M1 28.8027C17.6508 20.3626 63.9476 8.17089 113.509 17.8802C166.729 28.3062 341.329 42.704 353 1"
                                                stroke="#FFBB38"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                            />
                                        </svg>
                                    </div> */}
                                </div>

                                <div className="mb-4 font-bold">
                                    <h3>Account's Details</h3>
                                </div>
                                <div className="input-area">
                                    <div className="flex sm:flex-row flex-col sm:space-x-5">
                                        <div className="w-1/2 input-item pb-2">
                                            <div className="h-[34px]">
                                                <InputCom
                                                    placeholder="Abcd"
                                                    label="First Name *"
                                                    name="f_name"
                                                    type="text"
                                                    inputClasses="h-[34px]"
                                                    inputClassesError="border-red-border"
                                                    inputHandler={handelInputs}
                                                    value={formValues.f_name}
                                                />
                                            </div>
                                            <div>
                                                <small className="mt-8 block text-red-700">{formErrors.f_name}</small>
                                            </div>
                                        </div>
                                        <div className="w-1/2 input-item pb-2">
                                            <div className="h-[34px]">
                                                <InputCom
                                                    placeholder="Samaranayaka"
                                                    label="Last Name *"
                                                    name="l_name"
                                                    type="text"
                                                    inputClasses="h-[34px]"
                                                    inputHandler={handelInputs}
                                                    value={formValues.l_name}
                                                />
                                            </div>
                                            <div>
                                                <small className="mt-8 block text-red-700">{formErrors.l_name}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex sm:flex-row flex-col sm:space-x-5">
                                        <div className="w-full input-item pb-2">
                                            <div className="h-[34px]">
                                                <InputCom
                                                    placeholder="+94 ** *** ****"
                                                    label="Phone *"
                                                    name="contact"
                                                    type="text"
                                                    inputClasses="h-[34px]"
                                                    inputHandler={handelInputs}
                                                    value={formValues.contact}
                                                />
                                            </div>
                                            <div>
                                                <small className="mt-8 block text-red-700">{formErrors.contact}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex sm:flex-row flex-col sm:space-x-5">
                                        <div className="w-full input-item pb-2">
                                            <div className="h-[34px]">
                                                <InputCom
                                                    placeholder="Your address Here"
                                                    label="Address *"
                                                    name="address"
                                                    type="text"
                                                    inputClasses="h-[34px]"
                                                    inputHandler={handelInputs}
                                                    value={formValues.address}
                                                />
                                            </div>
                                            <div>
                                                <small className="mt-8 block text-red-700">{formErrors.address}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex sm:flex-row flex-col sm:space-x-5">
                                        <div className="input-com w-1/2 input-item pb-2 h-[34px]">
                                            <label
                                                className="input-label text-qgray capitalize text-[13px] font-normal block mb-2 ">
                                                Town / City *
                                            </label>
                                                <select onChange={handelInputs} value={formValues.city} id="city"
                                                        className="border border-qgray-border overflow-hidden w-full h-[34px] text-qgray text-[13px] bg-white flex focus:outline-none justify-between items-center">
                                                    <option className="my-1" value="0">Select Your City</option>
                                                    <option className="mb-1" value="1">Colombo</option>
                                                    <option className="mb-1" value="2">Gampaha</option>
                                                    <option className="mb-1" value="3">Kalutara</option>
                                                </select>
                                            <div>
                                                <small className="block text-red-700">{formErrors.city}</small>
                                            </div>
                                        </div>
                                        <div className="w-1/2 h-full input-item pb-2">
                                            <div className="h-[34px]">
                                                <InputCom
                                                    label="Postcode / ZIP *"
                                                    inputClasses="w-full h-[34px]"
                                                    name="zip"
                                                    type="text"
                                                    placeholder="00000"
                                                    inputHandler={handelInputs}
                                                    value={formValues.zip}
                                                />
                                            </div>
                                            <div>
                                                <small className="mt-8 block text-red-700">{formErrors.zip}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4 font-bold">
                                        <h3>Authentication Details</h3>
                                    </div>
                                    <div className="flex sm:flex-row flex-col sm:space-x-5">
                                        <div className="w-full input-item pb-2">
                                            <div className="h-[34px]">
                                                <InputCom
                                                    placeholder="abc@gmail.com"
                                                    label="Email Address / User Name *"
                                                    name="email"
                                                    type="email"
                                                    inputClasses="h-[34px]"
                                                    inputHandler={handelInputs}
                                                    value={formValues.email}
                                                />
                                            </div>
                                            <div>
                                                <small className="mt-8 block text-red-700">{formErrors.email}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex sm:flex-row flex-col sm:space-x-5">
                                        <div className="w-1/2 input-item pb-2">
                                            <div className="h-[34px]">
                                                <InputCom
                                                    placeholder="● ● ● ● ● ●"
                                                    label="Password *"
                                                    name="password"
                                                    type="password"
                                                    inputClasses="h-[34px]"
                                                    inputHandler={handelInputs}
                                                    value={formValues.password}
                                                />
                                            </div>
                                            <div>
                                                <small className="mt-8 block text-red-700">{formErrors.password}</small>
                                            </div>
                                        </div>
                                        <div className="w-1/2 input-item pb-2">
                                            <div className="h-[34px]">
                                                <InputCom
                                                    placeholder="● ● ● ● ● ●"
                                                    label="Re-enter Password *"
                                                    name="rePassword"
                                                    type="password"
                                                    inputClasses="h-[34px]"
                                                    inputHandler={handelInputs}
                                                    value={formValues.rePassword}
                                                />
                                            </div>
                                            <div>
                                                <small className="mt-8 block text-red-700">{formErrors.rePassword}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="forgot-password-area mb-7">
                                        <div className="remember-checkbox flex items-center space-x-2.5">
                                            <button
                                                onClick={rememberMe} type="button"
                                                className="w-5 h-5 text-qblack flex justify-center items-center border border-light-gray"
                                            >
                                                {checked && (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-5 w-5"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                )}
                                            </button>
                                            <span onClick={rememberMe} className="text-base text-black">
                                                I agree to all
                                                <span className="text-qblack"> terms and conditions </span>
                                                of items.lk
                                            </span>
                                        </div>
                                    </div>

                                    <div className="signin-area mb-3">
                                        <div className="flex justify-center">
                                            <button onClick={handleSubmit}
                                                type="button"
                                                disabled={formSubmit}
                                                className="black-btn text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-purple items-center"
                                            >
                                                <span>Create Account</span>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="signup-area flex justify-center">
                                        <p className="text-base text-qgraytwo font-normal">
                                            Already have an Account?
                                            <Link to={'/login'} className="ml-2 text-qblack"> Log In</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 lg:flex hidden transform scale-60 xl:scale-100   xl:justify-center">
                            <div
                                className="absolute xl:-right-20 -right-[138px]"
                                style={{ top: "calc(50% - 300px)" }}
                            >
                                <Thumbnail />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    );
}

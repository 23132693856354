import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function ReturnRefundPolicy() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="terms-condition-page w-full bg-white pb-[30px]">
        <div className="w-full mb-[30px]">
          <PageTitle
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Return and Refund Policy", path: "/retun-refund-policy" },
            ]}
            title="Return and Refund Policy"
          />
        </div>
        <div className="w-full">
          <div className="container-x mx-auto">
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-4">
                Last updated: January 08, 2024
              </h2>
              <h2 className="text-[22px] font-medium text-qblack mb-5">
                Thank you for shopping at Items.lk.
              </h2>
              <p className="text-[15px] text-justify text-qgraytwo leading-7 mb-2">
                If, for any reason, You are not completely satisfied with a purchase We invite You to review
                our policy on refunds and returns. This Return and Refund Policy has been created with the
                help of the Free Return and Refund Policy Generator.
              </p>
              <p className="text-[15px] text-justify text-qgraytwo leading-7">
                The following terms are applicable for any products that You purchased with Us.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-4">
                Interpretation and Definitions
              </h2>
              <h2 className="text-[16px] font-medium text-qblack mb-3">
                Interpretation
              </h2>
              <p className="text-[15px] text-justify text-qgraytwo leading-7 mb-5">
                The words of which the initial letter is capitalized have meanings defined under the
                following conditions. The following definitions shall have the same meaning regardless of
                whether they appear in singular or in plural.
              </p>
              <h2 className="text-[16px] font-medium text-qblack mb-3">
                Definitions
              </h2>
              <p className="text-[15px] text-justify text-qgraytwo leading-7 mb-2">
                For the purposes of this Return and Refund Policy:
              </p>
              <ul className="list-disc ml-5 mb-5">
                <li className="text-[15px] text-justify text-qgraytwo leading-7">
                  <strong className="text-[#222222]">Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this
                  Agreement) refers to Items.lk, 26/1/2 Hill Street, Dehiwala.
                </li>
                <li className="text-[15px] text-justify text-qgraytwo leading-7">
                  <strong className="text-[#222222]">Goods</strong> refer to the items offered for sale on the Service.
                </li>
                <li className="text-[15px] text-justify text-qgraytwo leading-7">
                  <strong className="text-[#222222]">Orders</strong> mean a request by You to purchase Goods from Us.
                </li>
                <li className="text-[15px] text-justify text-qgraytwo leading-7">
                  <strong className="text-[#222222]">Service</strong> refers to the Website.
                </li>
                <li className="text-[15px] text-justify text-qgraytwo leading-7">
                  <strong className="text-[#222222]">Website</strong> refers to Items.lk, accessible from www.items.lk
                </li>
                <li className="text-[15px] text-justify text-qgraytwo leading-7">
                  <strong className="text-[#222222]">You</strong> means the individual accessing or using the Service, or the company, or other
                  legal entity on behalf of which such individual is accessing or using the Service, as
                  applicable.
                </li>
              </ul>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                Your Order Cancellation Rights
              </h2>
              <p className="text-[15px] text-justify text-qgraytwo leading-7 mb-2">
                You are entitled to cancel Your Order within 7 days without giving any reason for doing so.
              </p>
              <p className="text-[15px] text-justify text-qgraytwo leading-7 mb-2">
                The deadline for cancelling an Order is 7 days from the date on which You received the
                Goods or on which a third party you have appointed, who is not the carrier, takes
                possession of the product delivered.
              </p>
              <p className="text-[15px] text-justify text-qgraytwo leading-7 mb-2">
                In order to exercise Your right of cancellation, You must inform Us of your decision by
                means of a clear statement. You can inform us of your decision by:
              </p>
              <ul className="list-disc ml-5 mb-5">
                <li className="text-[15px] text-justify text-qgraytwo leading-7">
                  By email: info@items.lk
                </li>
                <li className="text-[15px] text-justify text-qgraytwo leading-7">
                  By visiting this page on our website: www.items.lk
                </li>
              </ul>
              <p className="text-[15px] text-justify text-qgraytwo leading-7">
                We will reimburse You no later than 14 days from the day on which We receive the
                returned Goods. We will use the same means of payment as You used for the Order, and You
                will not incur any fees for such reimbursement.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                Conditions for Returns
              </h2>
              <p className="text-[15px] text-justify text-qgraytwo leading-7 mb-2">
                In order for the Goods to be eligible for a return, please make sure that:
              </p>
              <ul className="list-disc ml-5 mb-5">
                <li className="text-[15px] text-justify text-qgraytwo leading-7">
                  The Goods were purchased in the last 7 days
                </li>
                <li className="text-[15px] text-justify text-qgraytwo leading-7">
                  The Goods are in the original packaging
                </li>
              </ul>
              <p className="text-[15px] text-justify text-qgraytwo leading-7 mb-2">
                The following Goods cannot be returned:
              </p>
              <ul className="list-disc ml-5 mb-5">
                <li className="text-[15px] text-justify text-qgraytwo leading-7">
                  The supply of Goods made to Your specifications or clearly personalized.
                </li>
                <li className="text-[15px] text-justify text-qgraytwo leading-7">
                  The supply of Goods which according to their nature are not suitable to be returned,
                  deteriorate rapidly or where the date of expiry is over.
                </li>
                <li className="text-[15px] text-justify text-qgraytwo leading-7">
                  The supply of Goods which are not suitable for return due to health protection or
                  hygiene reasons and were unsealed after delivery.
                </li>
                <li className="text-[15px] text-justify text-qgraytwo leading-7">
                  The supply of Goods which are, after delivery, according to their nature, inseparably
                  mixed with other items.~
                </li>
              </ul>
              <p className="text-[15px] text-justify text-qgraytwo leading-7 mb-2">
                We reserve the right to refuse returns of any merchandise that does not meet the above
                return conditions in our sole discretion.
              </p>
              <p className="text-[15px] text-justify text-qgraytwo leading-7">
                Only regular priced Goods may be refunded. Unfortunately, Goods on sale cannot be
                refunded. This exclusion may not apply to You if it is not permitted by applicable law.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                Returning Goods
              </h2>
              <p className="text-[15px] text-justify text-qgraytwo leading-7 mb-2">
                You are responsible for the cost and risk of returning the Goods to Us. You should send the
                Goods at the following address:
              </p>
              <p className="text-[16px] text-justify text-qgraytwo leading-7 mb-2">
                26/1/2 Hill Street, Dehiwala.
              </p>
              <p className="text-[15px] text-justify text-qgraytwo leading-7">
                We cannot be held responsible for Goods damaged or lost in return shipment. Therefore,
                We recommend an insured and trackable mail service. We are unable to issue a refund
                without actual receipt of the Goods or proof of received return delivery.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                Gifts
              </h2>
              <p className="text-[15px] text-justify text-qgraytwo leading-7 mb-2">
                If the Goods were marked as a gift when purchased and then shipped directly to you, You'll
                receive a gift credit for the value of your return. Once the returned product is received, a gift
                certificate will be mailed to You.
              </p>
              <p className="text-[15px] text-justify text-qgraytwo leading-7">
                If the Goods weren't marked as a gift when purchased, or the gift giver had the Order
                shipped to themselves to give it to You later, We will send the refund to the gift giver.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                Contact Us
              </h2>
              <p className="text-[15px] text-justify text-qgraytwo leading-7 mb-2">
                If you have any questions about our Returns and Refunds Policy, please contact us:
              </p>
              <ul className="list-disc ml-5 mb-5">
                <li className="text-[15px] text-justify text-qgraytwo leading-7">
                  By email: info@items.lk
                </li>
                <li className="text-[15px] text-justify text-qgraytwo leading-7">
                  By visiting this page on our website: www.items.lk
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout >
  );
}

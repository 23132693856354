import React from "react";

const Pagination = ({
  totalLength,
  itemsPerPage,
  currentPage,
  changePageNumber,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalLength / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <ul className="flex justify-center items-center space-x-2">
      <li
        className={`cursor-pointer px-3 py-1 rounded-md ${
          currentPage === 1 ? "bg-gray-300" : "bg-gray-200 hover:bg-gray-300"
        }`}
        onClick={() => currentPage > 1 && changePageNumber(currentPage - 1)}
      >
        &lt;
      </li>
      {pageNumbers.map((number) => (
        <li
          key={number}
          className={`cursor-pointer px-3 py-1 rounded-md ${
            number === currentPage
              ? "bg-blue-500 text-white"
              : "bg-gray-200 hover:bg-gray-300"
          }`}
          onClick={() => changePageNumber(number)}
        >
          {number}
        </li>
      ))}
      <li
        className={`cursor-pointer px-3 py-1 rounded-md ${
          currentPage === pageNumbers.length
            ? "bg-gray-300"
            : "bg-gray-200 hover:bg-gray-300"
        }`}
        onClick={() =>
          currentPage < pageNumbers.length && changePageNumber(currentPage + 1)
        }
      >
        &gt;
      </li>
    </ul>
  );
};

export default Pagination;

import useAuthHook from "../../hooks/useAuthHook";
import {Navigate, Outlet, useLocation} from "react-router-dom";

const RequireAuth = () => {
  const { auth } = useAuthHook();
  const location = useLocation();

  return(
      auth?.username
          ? <Outlet />
          : <Navigate to="/Login" state={{from: location}} replace />
  );
}

export default RequireAuth;
import React, { useEffect, useState } from 'react';
import "./preloader.css";

const Preloader = () => {
  return (
      <div className="preloader">
          {/*<hr /><hr /><hr /><hr />*/}
      </div>
  );
};

export default Preloader;

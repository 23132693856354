import {Link} from "react-router-dom";

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'LKR',
});

export default function ProductCardRowStyleTwo({className, datas}) {

    let price;
    if (datas.minp === datas.maxp) {
        price = formatter.format(datas.maxp);
    } else {
        let p1 = formatter.format(datas.minp);
        let p2 = formatter.format(datas.maxp);
        price = p1 + '-' + p2;
    }

    return (
        <div
            data-aos="fade-up"
            className={`product-card-row-two w-full  ${className || ""}`}
        >
            <div className="w-full h-[105px] bg-white border border-primarygray px-5 ">
                <div className="w-full h-full flex space-x-5 justify-center items-center">
                    <div className="w-[75px] h-[75px]">
                        <img
                            src={process.env.REACT_APP_IMAGE_FOLDER+`/`+datas.name}
                            alt=""
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex-1 h-full flex flex-col justify-center ">
                        <Link to={`/single-product/${datas.id}`}>
                            <p className="title mb-2 sm:text-[15px] text-[13px] font-600 text-qblack leading-[24px] line-clamp-1 hover:text-blue-600">
                                {datas.p_name}
                            </p>
                        </Link>

                        <p className="price">
                          {/*<span className="main-price text-qgray line-through font-600 text-[18px]">*/}
                          {/*  {datas.price}*/}
                          {/*</span>*/}
                            <span className="offer-price text-qred font-600 text-[18px] ml-2">
                                {price}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

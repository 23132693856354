import React, { useEffect, useState } from "react";
import InputCom from "../../Helpers/InputCom";
import Layout from "../../Partials/Layout";
import Thumbnail from "./Thumbnail";
import {LoginSocialFacebook} from "reactjs-social-login";
import {FacebookLoginButton, GoogleLoginButton} from "react-social-login-buttons";
import axios from "../../../api/axios";
import {toast} from "react-toastify";
import {Link, useLocation, useNavigate} from "react-router-dom";
import useAuthHook from "../../../hooks/useAuthHook";
import useCookiesHook from "../../../hooks/useCookiesHook";
import useFirebaseHook from "../../../hooks/useFirebaseHook";
import {GoogleAuthProvider, getAuth, signInWithPopup, FacebookAuthProvider} from "firebase/auth";

export default function Login() {

    const formInputs = {
        username: '',
        password: ''
    }

    const {setAuth} = useAuthHook();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/profile';
    const [checked, setValue] = useState(false);
    const [formValues, setFormValues] = useState(formInputs);
    const [FormErrors, setFormErrors] = useState([]);
    const [FormSubmit, setFormSubmit] = useState(false);
    const [googleCredential, setGoogleCredential] = useState(null);
    const [faceBookCredential, setFaceBookCredential] = useState(null);
    const {setCookieName, setCookie} = useCookiesHook();
    const controller = new AbortController()

    const facebookKey = process.env.REACT_APP_FB_APP_ID

    const authWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        const auth = getAuth(useFirebaseHook);
        await signInWithPopup(auth, provider).then(result => {
            setGoogleCredential(result)
        }).catch(error => {
            console.log(error)
            toast.error("Login Failed Please Try again later")
        });
    }

    const authWithFacebook = async () => {
        const provider = new FacebookAuthProvider();
        const auth = getAuth(useFirebaseHook);
        await signInWithPopup(auth, provider).then(result => {
            setFaceBookCredential(result)
        }).catch(error => {
            console.log(error)
            toast.error("Login Failed Please Try again later")
        });
    }

    const rememberMe = () => {
        setValue(!checked);
    };

    const handleInputs = (e) => {
        const { id, value } = e.target;
        setFormValues({ ...formValues, [id]: value })
    }

    const validation = (formValues) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const errors = {};
        if (formValues.username === "") {
            errors.username = "required";
        }
        if (formValues.password === "") {
            errors.password = "required";
        }
        if (!emailRegex.test(formValues.username)) {
            errors.username = "Please enter valid email";
        }
        setFormSubmit(false);
        return errors
    }

    const handleSubmit = () => {
        setFormErrors(validation(formValues))
        setFormSubmit(true);
    }

    const loginUser = async () => {
        return await axios.post('/webUser/login',
            formValues,
            {
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                withCredentials: true,
                signal: controller.signal
            }
        )
    }

    useEffect(() => {
        if (Object.keys(FormErrors).length === 0 && FormSubmit) {
            loginUser().then(response => {
                setAuth(
                    {
                        'username': formValues.username,
                        'id': response.data[1],
                        'accessToken': response.data[0]
                    }
                )
                setCookieName('login')
                setCookie('login', true, {maxAge: 24 * 60 * 60 * 1000, sameSite: true})
                window.scrollTo({ top: 0, behavior: 'smooth' });
                toast.success("Login Successfully")
                setFormSubmit(false)
                setFormValues(formInputs)
                setTimeout(() => {
                    navigate(from, {state: {from: location}, replace: true})
                }, 1500)
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warning("Username and Password are required")
                    }
                    if (error.response.status === 401) {
                        toast.warning("Username or Password not match")
                    }
                } else {
                    toast.error("Login Failed Please Try again later")
                }
                controller.abort()
            });
        }
    }, [checked, FormErrors, FormSubmit, navigate, setAuth, from, formValues, setCookieName, setCookie, formInputs])

    const LoginWithGoogle = async () => {
        return await axios.post('/webUser/LoginWithGoogle',
            googleCredential,
            {
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                withCredentials: true,
                signal: controller.signal
            })
    }
    useEffect(() => {
        if (googleCredential !== null) {
            LoginWithGoogle().then(response => {
                setAuth(
                    {
                        'username': googleCredential.user.email,
                        'id': response.data[1],
                        'accessToken': response.data[0]
                    }
                )
                setCookieName('login')
                setCookie('login', true, {maxAge: 24 * 60 * 60 * 1000, sameSite: true})
                window.scrollTo({top: 0, behavior: 'smooth'});
                toast.success("Login Successfully")
                setGoogleCredential(null)
                setTimeout(() => {
                    navigate(from, {state: {from: location}, replace: true})
                }, 1500)
            }).catch(error => {
                console.log(error)
                toast.error("Login Failed Please Try again later")
                controller.abort()
            })
        }
    }, [googleCredential])

    const LoginWithFaceBook = async () => {
        return await axios.post('/webUser/LoginWithFaceBook',
            googleCredential,
            {
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                withCredentials: true,
                signal: controller.signal
            })
    }
    useEffect(() => {
        if (faceBookCredential !== null) {
            LoginWithFaceBook().then(response => {
                setAuth(
                    {
                        'username': faceBookCredential.user.email,
                        'id': response.data[1],
                        'accessToken': response.data[0]
                    }
                )
                setCookieName('login')
                setCookie('login', true, {maxAge: 24 * 60 * 60 * 1000, sameSite: true})
                window.scrollTo({top: 0, behavior: 'smooth'});
                toast.success("Login Successfully")
                setGoogleCredential(null)
                setTimeout(() => {
                    navigate(from, {state: {from: location}, replace: true})
                }, 1500)
            }).catch(error => {
                console.log(error)
                toast.error("Login Failed Please Try again later")
                controller.abort()
            })
        }
    }, [faceBookCredential])

    return (
        <Layout childrenClasses="pt-0 pb-0">
            <div className="login-page-wrapper w-full py-10">
                <div className="container-x mx-auto">
                    <div className="lg:flex items-center relative">
                        <div
                            className="lg:w-[572px] w-full h-[691px] bg-white flex flex-col justify-center sm:p-10 p-5 border border-[#E0E0E0]">
                            <div className="w-full">
                                <div
                                    className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                                    <h1 className="text-[34px] font-bold leading-[74px] text-qblack">
                                        Log In
                                    </h1>
                                    {/* <div className="shape -mt-6">
                                        <svg
                                            width="172"
                                            height="29"
                                            viewBox="0 0 172 29"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M1 5.08742C17.6667 19.0972 30.5 31.1305 62.5 27.2693C110.617 21.4634 150 -10.09 171 5.08727"
                                                stroke="#FFBB38"
                                            />
                                        </svg>
                                    </div> */}
                                </div>
                                <form>
                                    <div className="input-area">
                                        <div className="input-item pb-2">
                                            <div className="h-[34px]">
                                                <InputCom
                                                    placeholder="abc@example.com"
                                                    label="Email Address *"
                                                    type="email"
                                                    name="username"
                                                    autoComplete="off"
                                                    required
                                                    inputClasses="h-[34px]"
                                                    inputHandler={handleInputs}
                                                    value={formValues.username}
                                                />
                                            </div>
                                            <div>
                                                <small className="mt-8 block text-red-700">{FormErrors.username}</small>
                                            </div>
                                        </div>
                                        <div className="input-item pb-2">
                                            <div className="h-[34px]">
                                                <InputCom
                                                    placeholder="● ● ● ● ● ●"
                                                    label="Password *"
                                                    type="password"
                                                    name="password"
                                                    required
                                                    inputClasses="h-[34px]"
                                                    inputHandler={handleInputs}
                                                    value={formValues.password}
                                                />
                                            </div>
                                            <div>
                                                <small className="mt-8 block text-red-700">{FormErrors.password}</small>
                                            </div>
                                        </div>
                                        <div className="forgot-password-area flex justify-between items-center mb-7">
                                            <div className="remember-checkbox flex items-center space-x-2.5">
                                                <button
                                                    onClick={rememberMe}
                                                    type="button"
                                                    className="w-5 h-5 text-qblack flex justify-center items-center border border-light-gray"
                                                >
                                                    {checked && (
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="h-5 w-5"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    )}
                                                </button>
                                                <span onClick={rememberMe} className="text-base text-black">
                                                    Remember Me
                                                </span>
                                            </div>
                                            {/*<a href="/forgot-password"  className="text-base text-qyellow" >*/}
                                            {/*    Forgot Password*/}
                                            {/*</a>*/}
                                        </div>
                                        <div className="signin-area">
                                            <div className="flex justify-center">
                                                <button
                                                    type="button"
                                                    onClick={handleSubmit}
                                                    className="black-btn mb-4 text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-purple items-center"
                                                >
                                                    <span>Log In</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="md:flex items-center relative mb-5">
                                    {/* Google Login */}
                                    <div className="md:w-1/2 sm:w-full">
                                        <div className="flex justify-center">
                                            <div
                                                className="h-[50px] w-full text-sm fb-btn-text flex justify-center items-center">
                                                <GoogleLoginButton onClick={authWithGoogle}/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Facebook Login */}
                                    <div className="md:w-1/2 sm:w-full">
                                        <div className="flex justify-center">
                                            <div
                                                className="h-[50px] w-full text-sm fb-btn-text flex justify-center items-center">
                                                <FacebookLoginButton onClick={authWithFacebook}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="signup-area flex justify-center">
                                    <p className="text-base text-qgraytwo font-normal">
                                        Don’t have an account ?
                                        <Link to="/signup" className="ml-2 text-qblack">
                                            Sign up for free
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 lg:flex hidden transform scale-60 xl:scale-100 xl:justify-center ">
                            <div
                                className="absolute xl:-right-20 -right-[138px]"
                                style={{ top: "calc(50% - 300px)" }}
                            >
                                <Thumbnail />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Routers from "./Routers";
import Preloader from "./components/Helpers/Preloader";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    document.body.classList.add("home-one");
  }, [location.pathname]);

  return isLoading ? <Preloader/> : <Routers />;
}

export default App;

import { useEffect, useState } from "react";

export default function InputQuantityWishList(props) {

  let newQuantity = props.newQty;
  let itemPrice = props.itemPrice;

  localStorage.setItem('updatedTotal', newQuantity * itemPrice);

  const [quantity, setQuantity] = useState(1);
  const increment = () => {
    // setQuantity((prev) => prev + 1);
    setQuantity(localStorage.setItem('updatedQty', parseInt(localStorage.getItem('updatedQty')) + 1));
    // alert(quantity);
  };
  const decrement = () => {
    if (quantity > 1) {
      // setQuantity((prev) => prev - 1);
      setQuantity(localStorage.setItem('updatedQty', parseInt(localStorage.getItem('updatedQty')) - 1));
    }
  };

  useEffect(() => {
    setQuantity(localStorage.getItem('updatedQty'));
  }, [])

  return (
    <div className="w-[120px] h-[40px] px-[26px] flex items-center border border-qgray-border">
      <div className="flex justify-between items-center w-full">
        <button
          onClick={decrement}
          type="button"
          className="text-base text-qgray"
        >
          -
        </button>
        <span className="text-qblack">{quantity}</span>
        <button
          onClick={increment}
          type="button"
          className="text-base text-qgray"
        >
          +
        </button>
      </div>
    </div>
  );
}

import { Link } from "react-router-dom";
import Facebook from "../../../Helpers/icons/Facebook";
import Instagram from "../../../Helpers/icons/Instagram";
import Twitter from "../../../Helpers/icons/Twitter";
import {ToastContainer} from "react-toastify";

export default function Footer({ type }) {

    return (
        <footer className="footer-section-wrapper bg-white print:hidden">
            <div className="container-x block mx-auto pt-[56px]">
                <div className="w-full flex flex-col items-center mb-[50px]">
                    {/* logo area */}
                    <div className="mb-[40px]">
                        <Link to="/">
                            <img
                                width="200"
                                height="77"
                                src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                                alt="logo"
                            />
                        </Link>
                    </div>
                    <div className="w-full h-[1px] bg-[#E9E9E9]"></div>
                </div>
                <div className="lg:flex justify-between mb-[50px]">
                    <div className="lg:w-[424px]  ml-0 w-full mb-10 lg:mb-0">
                        <h1 className="text-[18] font-500 text-[#2F2F2F] mb-5">About Us</h1>
                        <p className="text-[#9A9A9A] text-[15px] w-[247px] leading-[28px] text-justify">
                            Welcome to <Link to="/" className="text-blue-900">Items.lk </Link>, a pioneering force in Sri Lanka's mobile accessories and electronics landscape since 2014.</p>
                    </div>
                    <div className="flex-1 lg:flex">
                        <div className="lg:w-1/3 w-full mb-10 lg:mb-0">
                            <div className="mb-5">
                                <h6 className="text-[18] font-500 text-[#2F2F2F]">
                                    Featured
                                </h6>
                            </div>
                            <div>
                                <ul className="flex flex-col space-y-4 ">
                                    <li>
                                        <Link to="/about">
                                            <span
                                                className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                                                About Us
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">
                                            <span
                                                className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                                                Contact Us
                                            </span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="lg:w-1/3 lg:flex lg:flex-col items-center w-full mb-10 lg:mb-0 ">
                            <div>
                                <div className="mb-5">
                                    <h6 className="text-[18] font-500 text-[#2F2F2F]">
                                        General Links
                                    </h6>
                                </div>
                                <div>
                                    <ul className="flex flex-col space-y-4 ">
                                        {/* <li>
                                            <Link to="/blogs">

                                                <span
                                                    className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                                                    Blog
                                                </span>
                                            </Link>
                                        </li> */}
                                        {/* <li>
                                            <Link to="/tracking-order">

                                                <span
                                                    className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                                                    Tracking Order
                                                </span>
                                            </Link>
                                        </li> */}
                                        {/*<li>*/}
                                        {/*    <Link to="/become-saller">*/}

                                        {/*        <span*/}
                                        {/*            className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">*/}
                                        {/*            Become Seller*/}
                                        {/*        </span>*/}
                                        {/*    </Link>*/}
                                        {/*</li>*/}
                                        <li>
                                            <Link to="/terms-condition">
                                            <span
                                                className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                                                Terms & Conditions
                                            </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/privacy-policy">
                                            <span
                                                className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                                                Privacy Policy
                                            </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/retun-refund-policy">
                                            <span
                                                className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                                                Return & Refund Policy
                                            </span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-1/3 lg:flex lg:flex-col items-center w-full mb-10 lg:mb-0">
                            <div>
                                <div className="mb-5">
                                    <h6 className="text-[18] font-500 text-[#2F2F2F]">
                                        Helpful
                                    </h6>
                                </div>
                                <div>
                                    <ul className="flex flex-col space-y-4 ">
                                        {/*<li>*/}
                                        {/*    <Link to="/#">*/}
                                        {/*        <span*/}
                                        {/*            className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">*/}
                                        {/*            Flash Sale*/}
                                        {/*        </span>*/}
                                        {/*    </Link>*/}
                                        {/*</li>*/}
                                        <li>
                                            <Link to="/faq">
                                                <span
                                                    className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                                                    FAQ
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/about">
                                                <span
                                                    className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                                                    Support
                                                </span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-bar border-t border-qgray-border lg:h-[82px] lg:flex justify-between items-center">
                    <div className="flex lg:space-x-5 justify-between items-center mb-3">
                        <div className="flex space-x-5 items-center">
                            <Link to="https://web.facebook.com/items.lk7">
                                <Facebook className="fill-current text-qgray hover:text-qblack" />
                            </Link>
                            <Link to="https://www.instagram.com/items.lk/" target="_blank">
                                <Instagram className="fill-current text-qgray hover:text-qblack" />
                            </Link>
                            {/* <Link to="https://twitter.com/?lang=en">
                                <Twitter className="fill-current text-qgray hover:text-qblack" />
                            </Link> */}
                        </div>
                        <span className="sm:text-base text-[10px] text-qgray font-300">
                            © {new Date().getFullYear()}
                            <button
                                rel="noreferrer"
                                className="font-500 text-qblack mx-1"
                            >
                                items.lk
                            </button>
                            All rights reserved
                        </span>
                    </div>
                    <div className="">
                        {/*<Link to="#">*/}
                        {/*    <img*/}
                        {/*        width="318"*/}
                        {/*        height="28"*/}
                        {/*        src={`${process.env.PUBLIC_URL}/assets/images/payment-getways.png`}*/}
                        {/*        alt="payment-getways"*/}
                        {/*    />*/}
                        {/*</Link>*/}
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </footer>

    );
}
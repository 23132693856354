import useAuthHook from "./useAuthHook";
import axios from "../api/axios";
import useCookiesHook from "./useCookiesHook";
import {useLocation, useNavigate} from "react-router-dom";

const useLogoutHook = () => {
    const {setAuth} = useAuthHook();
    const {setCookieName, removeCookie} = useCookiesHook();
    const location = useLocation();
    const navigate = useNavigate()
    const from = location.state?.from?.pathname || '/login';

    return async () => {
        await axios.delete('/webUser/logout', {withCredentials: true}).then(response => {
            setCookieName('login');
            setAuth({});
            removeCookie('login', false, {maxAge: 24 * 60 * 60 * 1000, sameSite: true})
            navigate(from, {state: {from: location}, replace: true})
        }).catch(err => {
            console.log(err);
        });
    };
}

export default useLogoutHook;
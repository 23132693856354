import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import Checkbox from "../Helpers/Checkbox";
import { useEffect, useState } from "react";
import axios, { all } from "axios";

export default function ProductsFilter({
  filters,
  updateFilters,
  priceRange,
  priceRangeHandler,
  className,
  filterToggle,
  filterToggleHandler,
  categoryId,
}) {
  const [productSubCategoryList, setProductSubCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);

  const hasActiveFilters = Object.values(filters).some(
    (value) => value === true
  );

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const brand = async () => {
      return await axios.get(
        `${process.env.REACT_APP_REDIRECT_URI}/product/getBrandListByCategory/${categoryId}`
      );
    };
    brand()
      .then((response) => {
        if (isMounted) setBrandList(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    const subCategories = async () => {
      return await axios.get(
        `${process.env.REACT_APP_REDIRECT_URI}/subcategory/getByCategory/${categoryId}`
      );
    };
    subCategories()
      .then((response) => {
        if (isMounted) setProductSubCategoryList(response.data);
        console.log("Subcategories: ", response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        isMounted = false;
        controller.abort();
      });
  }, [categoryId]);

  const checkboxHandler = (e, id = null) => {
    const { name, checked } = e.target;

    console.log("Checkbox Handler");
    console.log(name, checked);

    let newName = name;

    if (
      name.includes("subcategory") &&
      !name.split("subcategory")[0].includes("category")
    ) {
      newName = `category-${categoryId}-${name}`;
    }

    updateFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [newName]: checked };
      console.log("Updated Filters");
      console.log(updatedFilters);
      return updatedFilters;
    });
  };

  const clearFilters = () => {
    updateFilters({});
  };

  return (
    <>
      <div
        className={`filter-widget w-full fixed lg:relative left-0 top-0 h-screen lg:h-auto overflow-y-scroll lg:overflow-y-auto bg-white px-[30px] pt-[40px] ${
          className || ""
        }  ${filterToggle ? "block" : "hidden lg:block"}`}
      >
        {/* Added Filters */}
        {hasActiveFilters && (
          <div className="filter-subject-item pb-10 border-b border-qgray-border">
            <div className="subject-title mb-[30px] flex items-center justify-between">
              <h1 className="text-black text-base font-500">Added Filters</h1>
              <button
                onClick={clearFilters}
                className="text-xs font-500 underline"
              >
                Clear All
              </button>
            </div>
            <div className="flex space-x-[5px] flex-wrap">
              {Object.entries(filters).map(([key, value]) => {
                let keyName = "";

                console.log("Key: ", key);
                console.log("productSubCategoryList", productSubCategoryList);
                if (key.includes("subcategory")) {
                  const subCategory = productSubCategoryList.find(
                    (subCategory) =>
                      subCategory.id === parseInt(key.split("-")[3])
                  );
                  keyName = subCategory.product_sub_cat_name;
                }

                if (key.includes("brand")) {
                  const brand = brandList.find(
                    (brand) => brand.p_brand === key.split("-")[1]
                  );
                  keyName = brand.p_brand;
                }

                return (
                  value && (
                    <span
                      key={key}
                      className="font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] flex items-center justify-between"
                    >
                      {keyName}
                      <button
                        onClick={() => {
                          checkboxHandler(
                            {
                              target: {
                                name: key,
                                checked: false,
                              },
                            },
                            key.split("-")[3]
                          );
                        }}
                        className="ml-2 font-500"
                      >
                        x
                      </button>
                    </span>
                  )
                );
              })}
            </div>
          </div>
        )}

        {/* Product SubCategories */}
        <div className="filter-subject-item pb-10 border-b border-qgray-border mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">
              Product Subcategories
            </h1>
          </div>
          <div className="filter-items">
            <ul>
              {productSubCategoryList.map((subCategory) => (
                <li key={subCategory.id} className="item mb-5">
                  <div className="flex justify-between items-center">
                    <div className="flex  space-x-[14px] items-center">
                      <Checkbox
                        id={`subcategory-${subCategory.id}`}
                        name={`subcategory-${subCategory.id}`}
                        handleChange={(e) => checkboxHandler(e, subCategory.id)}
                        checked={
                          !!filters[
                            `category-${categoryId}-subcategory-${subCategory.id}`
                          ]
                        } // Use !! to ensure a boolean value
                      />
                      <label
                        htmlFor={`category-${subCategory.id}`}
                        className="text-xs font-black font-400 capitalize"
                      >
                        {subCategory.product_sub_cat_name}
                      </label>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Price Range */}
        <div className="filter-subject-item pb-10 border-b border-qgray-border mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Price Range</h1>
          </div>
          <div className="price-range mb-5">
            <InputRange
              draggableTrack
              maxValue={50000}
              minValue={0}
              value={priceRange}
              onChange={priceRangeHandler}
              step={100}
            />
          </div>
          <p className="text-xs text-qblack font-400">
            Price: {priceRange.min} LKR - {priceRange.max} LKR
          </p>
        </div>

        {/* Brands */}
        <div className="filter-subject-item pb-10 border-b border-qgray-border mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Brands</h1>
          </div>
          <div className="filter-items">
            <ul>
              {brandList.map((brands, index) => (
                <li
                  key={index}
                  className="item flex justify-between items-center mb-5"
                >
                  <div className="flex space-x-[14px] items-center">
                    <Checkbox
                      id={`brand-${index}`}
                      name={`brand-${brands.p_brand}`}
                      handleChange={(e) => checkboxHandler(e, brands.p_brand)}
                      checked={!!filters[`brand-${brands.p_brand}`]}
                    />
                    <label
                      htmlFor={`brand-${brands.p_brand}`}
                      className="text-xs font-black font-400 capitalize"
                    >
                      {brands.p_brand}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div
        onClick={filterToggleHandler}
        className={`offcanvas-overlay fixed inset-0 bg-black bg-opacity-50 z-50 cursor-pointer ${
          filterToggle ? "block lg:hidden" : "hidden"
        }`}
      />
    </>
  );
}
